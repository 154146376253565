import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var bonusAmount = function (user) {
    var hasPermissionCreateBonusAmount = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CONFIG_BONUS_AMOUNT, MethodCode.CREATE);
    var hasPermissionListBonusAmount = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CONFIG_BONUS_AMOUNT, MethodCode.LIST);
    var hasPermissionViewBonusAmount = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CONFIG_BONUS_AMOUNT, MethodCode.VIEW);
    var hasPermissionDeleteBonusAmount = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CONFIG_BONUS_AMOUNT, MethodCode.DELETE);
    var hasPermissionEditBonusAmount = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CONFIG_BONUS_AMOUNT, MethodCode.EDIT);
    return {
        hasPermissionEditBonusAmount: hasPermissionEditBonusAmount,
        hasPermissionCreateBonusAmount: hasPermissionCreateBonusAmount,
        hasPermissionListBonusAmount: hasPermissionListBonusAmount,
        hasPermissionViewBonusAmount: hasPermissionViewBonusAmount,
        hasPermissionDeleteBonusAmount: hasPermissionDeleteBonusAmount,
    };
};
export default bonusAmount;
